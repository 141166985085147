<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 12 12"
  >
    <path
      fill="currentColor"
      d="m4.85 8.92-.72-.72 1.7-1.7H0v-1h5.8L4.1 3.8l.72-.72 2.93 2.94-2.9 2.9ZM6.15 12v-1H11V1H6.15V0H12v12H6.15Z"
    />
  </svg>
</template>

